<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full justify-center p-4 text-center items-center">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-[20px] md:rounded-[35px] bg-[#f9f8f6] text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div class="bg-faun px-3 py-6 sm:px-6 sm:px-6 sm:py-6 lg:px-8 lg:py-10">
                <div>
                  <div class="mt-6 text-center">
                    <DialogTitle as="h2" class="text-2xl sm:text-3xl lg:text-4xl lora leading-6 text-gray-900">
                      Let us show you around
                    </DialogTitle>
                    <button @click="open = false" class="absolute top-4 right-4 text-gray-900 hover:text-gray-500 focus-visible:none">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                    <div class="mt-4">
                      <p class="text-base text-gray-600">
                        The best way to discover Elker is through a guided demo
                      </p>
                    </div>

                    <div class="w-full mt-4 bg-slate-900 text-white font-sans shadow-[0_0_30px_10px_rgba(0,0,0,0.1)] overflow-hidden rounded-lg ring-media-focus data-[focus]:ring-4" 
                    style="position:relative; padding-top:56.25%;">
                      <iframe src="https://iframe.mediadelivery.net/embed/28647/6a97a963-883c-4318-b591-d2233ff634e1?autoplay=false&loop=false&muted=false&preload=true&responsive=true" loading="lazy" style="border:0;position:absolute;top:0;height:100%;width:100%;" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe>
                    </div>

                    <div class="mt-6">
                      <button
                      @click="calendlyPopup"
                      class="rounded-md transition bg-[#8293fa] border-2 border-[#5a6ded] hover:bg-[#5a6ded] px-3.5 py-2.5 text-sm font-semibold text-white focus:outline-none"
                      >
                        Book a demo
                      </button>
                      <button
                      @click="toToolkit" 
                      class="ml-4 rounded-md transition bg-transparent hover:bg-[rgba(0,0,0,0.03)] px-3.5 py-2.5 text-sm font-semibold text-[#5a6ded] focus:outline-none"
                      >
                        Get a quote ->
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

const userStore = useUserStore()
const router = useRouter()

const { 
  removeModal3, 
  mobileRemoveModal, 
  mobileScrolledDown, 
  canTrk,
  sessionStart
} = storeToRefs(userStore)

const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

const { $isMob } = useNuxtApp()

const open = ref(false)

userStore.startSession()

const calendlyPopup = async () => {
  Calendly.initPopupWidget({
    url: 'https://calendly.com/initial-scoping-team/scoping-discussion?hide_gdpr_banner=1&primary_color=096b72'
  })
  if (userStore.sentToPipedrive !== true) {
    if (process.client && window.dataLayer) {
      window.dataLayer.push({
        event: 'elker_conversion'
      })
    }
  }
  return false
}

const toToolkit = async () => {
  router.push({ path: "/pricing" })
  open.value = false
}

const handleMouseMove = event => {
  if (event.clientY <= 50) {
    if (
      !open.value && 
      !removeModal3.value &&
      Date.now() > sessionStart.value + 40000
    ) {
      open.value = true
      userStore.removeModal3 = true
      removeListener()
    }
  }
}

const handleScroll = () => {
  const scrolledToTop = window.scrollY < 100
  if (window.scrollY > 300 && !mobileScrolledDown.value) {
    userStore.mobileScrolledDown = true
  }
  if (
    scrolledToTop && 
    mobileScrolledDown.value && 
    !mobileRemoveModal.value &&
    Date.now() > sessionStart.value + 40000
  ) {
    open.value = true
    userStore.mobileRemoveModal = true
    removeListener()
  }
}

const addMouseListener = () => {
  if ($isMob()) {
    window.addEventListener('scroll', handleScroll, { passive: true })
  } else {
    window.addEventListener('mousemove', handleMouseMove)
  }
}

const removeListener = () => {
  if ($isMob()) {
    window.removeEventListener('scroll', handleScroll)
  } else {
    window.removeEventListener('mousemove', handleMouseMove)
  }
}

onMounted(async () => {
  await delay(500)
  if (canTrk.value) {
    addMouseListener()
  }
})

</script>